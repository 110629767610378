import * as React from 'react';
import styled from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Link, graphql } from 'gatsby';

export const Head = () => (
  <>
    <title>O gabinecie | Gabinet Elżbieta</title>
    <link rel="canonical" href="https://gabinetelzbieta.pl/o-gabinecie/" />
  </>
);

const Main = styled.main`
  /* font-family: ${({ theme }) => theme.font.family.sans}; */

  p {
    line-height: 150%;
  }

  .subtitle {
    margin: 0;
    text-transform: uppercase;
  }
`;

const Slider = styled.section`
  .gatsby-image-wrapper-constrained {
    width: 100%;
  }
`;

const GridAbout = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  ${({ theme }) => theme.mqLaptop} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const GridAboutContent = styled.div`
  ${({ theme }) => theme.mqLaptop} {
    width: calc(100% - 360px);
  }
`;

const GridAboutImg = styled.div`
  figure {
    margin: 50px auto 0;
    max-width: 300px;
    text-align: center;

    ${({ theme }) => theme.mqLaptop} {
      margin-top: 0;
    }
  }

  figcaption {
    font-weight: 600;
    font-style: normal;
    font-size: 1.8rem;
  }
`;

const AboutOffice = ({ data }) => (
  <>
    <Slider>
      <GatsbyImage
        image={getImage(data.hero_1)}
        alt="Gabinet Elżbieta"
        loading="eager"
      />
    </Slider>

    <Main>
      <div className="row">
        <p className="subtitle">POZNAJ NAS</p>
        <h1>O gabinecie</h1>

        <GridAbout>
          <GridAboutContent>
            <p>
              <strong>Nasz gabinet kosmetyczny działa w&nbsp;Olsztynie od wielu lat.</strong>
            </p>

            <p>
              Zdobyliśmy w&nbsp;tym czasie wielu oddanych klientów oraz zyskaliśmy renomę najlepszej tego typu placówki w&nbsp;całym mieście. Dowodem naszego profesjonalizmu jest wyróżnienie, jakie otrzymaliśmy od prestiżowego magazyn
              {' '}
              <em>„Uroda Beauty Expert”</em>
              , które przyznaje on wyłącznie placówkom godnym zaufania i&nbsp;polecenia.
            </p>

            <p>
              Wykorzystujemy produkty renomowanych i&nbsp;sprowadzonych przedsiębiorstw. Współpracujemy z&nbsp;firmami takimi jak
              {' '}
              <em>Thalgo</em>
              ,
              {' '}
              <em>Bandi</em>
              ,
              {' '}
              <em>Image</em>
              {' '}
              oraz
              {' '}
              <em>RevitaLash</em>
              . Wykorzystanie najlepszych kosmetyków w&nbsp;połączeniu z&nbsp;wysokimi umiejętnościami naszych pracowników, gwarantuje naszym klientom pełnię bezpieczeństwa.
            </p>

            <p>Wszystkie zabiegi przeprowadzamy w&nbsp;możliwie najbardziej komfortowych warunkach – klimatyzowane pomieszczenia, relaksacyjna muzyka, przyjazna atmosfera. Klienci mogą liczyć zawsze na wysoki poziom obsługi. Staramy się wychodzić naprzeciw oczekiwaniom klientów, dlatego cenimy ich uwagi i&nbsp;propozycje zmian.</p>

            <p>
              <Link to="/oferta/">Nasza oferta</Link>
              {' '}
              obejmuje szereg zabiegów odmładzających skórę. Zajmujemy się również leczeniem laserem i&nbsp;leczeniem ultradźwiękami oraz wypełnianiem zmarszczek.
            </p>

            <p>Zapraszamy do dokładnego zapoznania się naszą ofertą usług kosmetycznych.</p>
          </GridAboutContent>

          <GridAboutImg>
            <figure>
              <GatsbyImage
                image={getImage(data.owner)}
                alt="Gabinet kosmetyczny Elżbieta Olsztyn"
                loading="eager"
              />
              <figcaption>
                Elżbieta Gutkowska
              </figcaption>
            </figure>
          </GridAboutImg>
        </GridAbout>
      </div>
    </Main>
  </>
);

export const query = graphql`
  query {
    hero_1: file(relativePath: {regex: "/hero\/1.jpg/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: DOMINANT_COLOR
          width: 2000
        )
      }
    },
    owner: file(relativePath: {regex: "/gabinet\/elzbieta-gutkowska.jpeg/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: DOMINANT_COLOR
          width: 300
        )
      }
    }
  }
`;

export default AboutOffice;
